<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in doneProducts"
        :key="index")
        .list-1
          .list-1__wrapper
              .list-1__qr-code
                qr-code-dropdown(
                  :name="item.name"
                  :url="item.qr_code")
              .list-1__subtitle {{ item.name }}
          .list-1__item
            .list-1__item-label {{ 'base.remainder' | translate }} [{{ 'units.kg' | translate }}]
            .list-1__item-value {{ item.weight }}
          .list-1__item
            .list-1__item-label {{ 'base.cost' | translate }}
            .list-1__item-value {{ item.cost }}

      v-table-body(
        v-if="!loading"
        outsideTable
        :empty="!doneProducts.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 2" :key="`${i}-${j}`")
            .list-1__item-skeleton

    template(
      v-if="!loading && showPagination")
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import QrCodeDropdown from '@/components/common/qr-code-dropdown/QrCodeDropdown'

import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'FinalProductTableMobile',

  components: {
    QrCodeDropdown
  },

  mixins: [paginationMixin],

  computed: {
    ...mapGetters([
      'doneProducts'
    ])
  },

  mounted () {
    this.setupPagination(this.fetchDoneProducts)
  },

  methods: {
    ...mapActions([
      'fetchDoneProducts'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
